<template>
<div id="page" class="city-partners-manage-index">
  <div class="cpmi-statistical-part" v-loading="countLoading">
    <statistical :data="statisticalData" />
  </div>
  <div class="cpmi-list-part">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-row>
        <el-form-item prop="keyword">
          <el-input v-model="form.keyword" placeholder="姓名/联系方式" clearable>
            <template #prepend>
              <i class="iconfont icon-sousuo" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="dateRange">
          <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item label="代理地区" prop="txt_area_code">
          <el-cascader v-model="form.txt_area_code" :options="regionOptions" :props="{checkStrictly: true, value: 'txt_code', label: 'txt_area_name', children: '_child'}" />
        </el-form-item>
        <el-form-item label="审核状态" prop="txt_verify_status">
          <el-select v-model="form.txt_verify_status" placeholder="请选择" size="small">
            <el-option v-for="item in statusOption" :key="item.id" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">搜索</el-button>
          <el-button type="info" @click="reset()">重置</el-button>
          <el-button type="primary" @click="goExport()">导出Excel</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <el-table :data="tableData" v-loading="tabelLoading">
      <el-table-column prop="txt_realname" label="姓名" align="center" />
      <el-table-column prop="txt_mobile" label="预留手机号" align="center">
        <template #default="scope">{{scope.row.txt_mobile||"- -"}}</template>
      </el-table-column>
      <el-table-column prop="txt_area_name" label="代理地区" align="center" />
      <el-table-column prop="txt_pname" label="上级代理" align="center" />
      <el-table-column prop="txt_verify_status" label="审核状态" align="center">
        <template #default="scope">
          <template v-if="scope.row.txt_verify_status==1">已通过</template>
          <template v-else-if="scope.row.txt_verify_status==2">未通过</template>
          <template v-else>待审核</template>
        </template>
      </el-table-column>
      <el-table-column prop="dt_apply" label="申请时间" align="center">
        <template #default="scope">{{scope.row.dt_apply||"- -"}}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="table-option">
        <template #default="scope">
          <div class="table-icon" @click="showDetail(scope.row)" v-if="scope.row.txt_verify_status!=null">
            <el-icon>
              <Document />
            </el-icon> 详情
          </div>
          <div class="table-icon" @click="goAudit(scope.row)" v-if="scope.row.txt_verify_status==null">
            <el-icon>
              <View />
            </el-icon> 审核
          </div>
          <!-- <div class="table-icon" v-if="scope.row.txt_verify_status==1" @click="send(scope.row)">
            <el-icon>
              <Position />
            </el-icon>
            发合同
          </div> -->
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  </div>
  <detail v-if="detailShow" :detailData="detailData" @close="()=>{detailShow=false;detailData=new Object();}" />
  <contract v-if="chooseShow" :chooseData="chooseData" @close="()=>{chooseShow=false;chooseData=new Object();getList();getCount()}" />
  <audit v-if="auditShow" :auditData="auditData" @close="()=>{auditShow=false;auditData=new Object();getList();getCount()}" />
</div>
</template>

<script>
// 市级合伙人管理
import { Statistical, Pagination } from "components";
import { onMounted, reactive, toRefs, ref } from 'vue';
import { Document, View } from "@element-plus/icons-vue";
import { countLevels, getAreas, getEveryLevelInfo } from "api/apis.js";
import { ElMessage, FormInstance } from 'element-plus';
import Detail from "./comps/Detail.vue";
import { Contract, Audit } from "components";
import { exportAgentList } from "assets/js/export";
export default {
  components: {
    Statistical,
    Pagination,
    Document,
    View,
    Detail,
    Contract,
    Audit
  },
  setup() {
    const state = reactive({
      formRef: ref(FormInstance),
      statisticalData: [
        { key: "cnt_allapplications", end: "人", value: 0, zn: "申请总数", eg: "Number of applications" },
        { key: "cnt_reviewed", value: 0, end: "人", zn: "待审核数量", eg: "Quantity be reviewed" },
        { key: "cnt_approved", value: 0, end: "人", zn: "已通过数量", eg: "Number of approved" },
        { key: "cnt_rejected", value: 0, end: "人", zn: "未通过数量", eg: "Number of rejected" }
      ],
      regionOptions: [],
      statusOption: [
        { id: 1, value: 3, label: "待审核" },
        { id: 2, value: 1, label: "已通过" },
        { id: 3, value: 2, label: "未通过" }
      ],
      form: {
        keyword: "",
        txt_area_code: "",
        txt_verify_status: null,
        dataRange: [],
        page: 1,
        limit: 10,
        int_agent_level: 2
      },
      tableData: [],
      countLoading: false,
      total: 0,
      tabelLoading: false,
      detailData: {},
      detailShow: false,
      chooseShow: false,
      chooseData: {},
      auditData: {},
      auditShow: false
    });

    onMounted(() => {
      getAreaOption();
      getCount();
      getList();
    });

    // 前往审核dialog
    const goAudit = item => {
      state.auditData = item;
      state.auditData.level = 2;
      state.auditShow = true;
    }

    // 导出
    const goExport = () => {
      // 请求参数赋值
      let param = {
        keyword: (state.form.keyword || "").trim() == "" ? null : state.form.keyword,
        txt_verify_status: state.form.txt_verify_status,
        txt_area_code: (state.form.txt_area_code || []).length > 0 ? state.form.txt_area_code[state.form.txt_area_code.length - 1] : null,
        page: 1,
        limit: 999,
        dt_from: null,
        dt_to: null,
        int_agent_level: state.form.int_agent_level
      }
      // 时间区间赋值
      if ((state.form.dateRange || []).length > 0) {
        param.dt_from = state.form.dateRange[0];
        param.dt_to = state.form.dateRange[1];
      }
      let keys = Object.keys(param);
      for (let i = 0; i < keys.length; i++) {
        if (param[keys[i]] == null) {
          delete param[keys[i]];
        }
      }
      state.tabelLoading = true;
      getEveryLevelInfo(param).then(response => {
        if (response.code == 200 && response.data) {
          exportAgentList(response.data.data, "市级合伙人");
        } 
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.tabelLoading = false;
      })
    };

    // 获取地区信息
    const getAreaOption = () => {
      getAreas().then(response => {
        if (response.code == 200 && response.data) {
          state.regionOptions = response.data;
        }
      }).catch(e => {
        console.error(e);
      })
    };

    // 打开选择合同模板 dialog
    const send = item => {
      state.chooseData = item;
      state.chooseShow = true;
    }

    // 显示详情
    const showDetail = (item) => {
      state.detailData = item;
      state.detailShow = true;
    }

    // 获取列表数据
    const getList = () => {
      // 请求参数赋值
      let param = {
        keyword: (state.form.keyword || "").trim() == "" ? null : state.form.keyword,
        txt_verify_status: state.form.txt_verify_status,
        txt_area_code: (state.form.txt_area_code || []).length > 0 ? state.form.txt_area_code[state.form.txt_area_code.length - 1] : null,
        page: state.form.page,
        limit: state.form.limit,
        dt_from: null,
        dt_to: null,
        int_agent_level: state.form.int_agent_level
      }
      // 时间区间赋值
      if ((state.form.dateRange || []).length > 0) {
        param.dt_from = state.form.dateRange[0];
        param.dt_to = state.form.dateRange[1];
      }
      let keys = Object.keys(param);
      for (let i = 0; i < keys.length; i++) {
        if (param[keys[i]] == null) {
          delete param[keys[i]];
        }
      }
      state.tabelLoading = true;
      getEveryLevelInfo(param).then(response => {
        if (response.code == 200 && response.data) {
          state.tableData = response.data.data;
          state.total = response.data.row;
        } else {
          state.tableData = [];
          state.total = 0;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.tabelLoading = false;
      })
    };
    // 重置表单
    const reset = () => {
      state.formRef.resetFields();
      getList();
    };

    // 统计
    const getCount = () => {
      state.countLoading = true;
      countLevels({ agent_level: 2 }).then(response => {
        if (response.code == 200) {
          let keys = Object.keys(response.data);
          for (let i = 0; i < keys.length; i++) {
            let index = state.statisticalData.findIndex(t => t.key == keys[i]);
            if (index != -1) {
              state.statisticalData[index].value = response.data[keys[i]];
            }
          }
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.countLoading = false;
      })
    };

    return {
      ...toRefs(state),
      getCount,
      getList,
      reset,
      getAreaOption,
      showDetail,
      send,
      goAudit,
      goExport
    };
  }
}
</script>

<style lang="scss" scoped>
.city-partners-manage-index {
  .cpmi-list-part {
    margin-top: 24px;
    height: calc(100% - 150px);

    .el-table {
      height: calc(100% - 100px);
    }
  }
}
</style>
