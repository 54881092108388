<template>
<el-dialog v-model="visible" title="省级合伙人详情" :before-close="close">
  <div class="dialog-body province-partner-detail" v-loading="loading">
    <div class="ppd-title">基本信息</div>
    <table class="ppd-base-info-table my-table">
      <tr>
        <th>姓名</th>
        <td>{{compData.txt_realname}}</td>
        <th>预留手机号</th>
        <td>{{compData.txt_mobile||"- -"}}</td>
      </tr>
      <tr>
        <th>申请地区</th>
        <td>{{compData.txt_area_name}}</td>
        <th>申请日期</th>
        <td>{{compData.dt_apply||"- -"}}</td>
      </tr>
      <tr>
        <th>身份证号</th>
        <td colspan="3">{{compData.identityCardNo}}</td>
      </tr>
      <tr>
        <th>银行卡号</th>
        <td colspan="3">{{compData.bankCardNo}}</td>
      </tr>
      <tr>
        <th>开户行信息</th>
        <td colspan="3">{{compData.bankName||"- -"}}</td>
      </tr>
      <tr>
        <th>微信登录地址</th>
        <td colspan="3">{{compData.txt_wechat_address||"- -"}}</td>
      </tr>
      <tr>
        <th>微信登录坐标</th>
        <td colspan="3">{{compData.txt_wechat_coordinate||"- -"}}</td>
      </tr>
      <tr>
        <th>审核状态</th>
        <td>
          <template v-if="compData.txt_verify_status==1">已通过</template>
          <template v-else-if="compData.txt_verify_status==2">未通过</template>
        </td>
        <th>审核时间</th>
        <td>{{compData.dt_verify||"- -"}}</td>
      </tr>
    </table>
    <!-- <div class="ppd-title">合同列表</div>
    <table class="my-table">
      <tr>
        <th>合同名称</th>
        <th>是否签署</th>
        <th>生效日期</th>
        <th>失效日期</th>
        <th>签署日期</th>
      </tr>
      <template v-if="(contractsData||[]).length==0">
        <tr>
          <td colspan="5">暂无数据</td>
        </tr>
      </template>
      <template v-else>
        <tr v-for="(item, index) in contractsData" :key="index">
          <td>{{item.txt_comments}}</td>
          <template v-if="item.txt_contract_status==1">
            <td>未签署</td>
          </template>
          <template v-else>
            <td @click="showContract(item)" class="can-click">已签署</td>
          </template>
          <td>{{item.dt_contract_effect||"- -"}}</td>
          <td>{{item.dt_contract_closing||"- -"}}</td>
          <td>{{item.dt_contract_sign||"- -"}}</td>
        </tr>
      </template>
    </table> -->
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="close" type="info">关 闭</el-button>
    </div>
  </template>
  <contract v-if="contractShow" :viewData="viewData" @close="()=>{contractShow=false;viewData=new Object();}" />
</el-dialog>
</template>

<script>
// 省级合伙人详情
import { onMounted, reactive, toRefs } from 'vue';
import { getAgentDetail } from "api/apis.js";
import { ElMessage } from 'element-plus';
import Contract from "./Contract.vue";
export default {
  props: { detailData: { type: Object } },
  components: {
    Contract,
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      loading: false,
      contractsData: [],
      contractShow: false,
      viewData: {},
      compData: {}
    });

    const close = () => {
      ctx.emit("close");
    };

    // 获取详情
    const getContractList = () => {
      state.loading = true;
      getAgentDetail({
        agent_id: prop.detailData.t_agent_id
      }).then(response => {
        if (response.code == 200) {
          state.compData = response.data;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.loading = false;
      })
    };

    // 预览合同
    const showContract = item => {
      state.contractShow = true;
      state.viewData.txt_contract_id = item.txt_contract_id;
      state.viewData.agentid = prop.detailData.t_agent_id;
    }

    onMounted(() => {
      getContractList();
    })

    return {
      ...toRefs(state),
      close,
      getContractList,
      showContract
    }
  }
}
</script>

<style lang="scss" scoped>
.province-partner-detail {
  .ppd-title {
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
