<template>
<el-dialog custom-class="view-contract-dialog" v-model="visible" title="预览合同" width="80%" :before-close="close" :close-on-click-modal="false">
  <div v-loading="dialogLoading" class="my-iframe">
    <iframe :src="url" scrolling="auto" frameborder="1" />
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="download" type="primary">下 载</el-button>
      <el-button @click="close" type="info">关 闭</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 预览合同
import { onMounted, reactive, toRefs } from 'vue';
import { viewContract, downloadContractById } from "api/apis.js";
import { ElMessage } from 'element-plus';
export default {
  props: {
    viewData: { type: Object },
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      dialogLoading: false,
      url: ""
    });

    onMounted(() => {
      getContractUrl();
    });

    // 获取合同预览链接
    const getContractUrl = () => {
      state.dialogLoading = true;
      viewContract(prop.viewData).then(response => {
        if (response.code == 200) {
          state.url = response.data.viewUrl;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.dialogLoading = false;
      })
    }

    // 下载合同
    const download = () => {
      downloadContractById({ txt_contract_id: prop.viewData.txt_contract_id }).then(response => {
        let urlObject = window.URL || window.webkitURL || window;
        // 文件流转义
        let blobUrl = new Blob([response]);
        let linkBtn = document.createElement('a');
        linkBtn.href = urlObject.createObjectURL(blobUrl);
        // 合同名称 - 可动态修改
        linkBtn.download = "合同协议.pdf";
        linkBtn.click();
      })
    }

    const close = () => {
      ctx.emit("close");
    }

    return {
      ...toRefs(state),
      close,
      getContractUrl,
      download
    }
  }
}
</script>

<style lang="scss">
.el-dialog {
  &.view-contract-dialog {
    margin-top: 8vh;
    height: calc(100% - 14vh);
  }
}

.my-iframe {
  height: 100%;

  iframe {
    height: 100%;
    width: 100%;
  }
}
</style>
